export default function urlCorrection(url) {
    if (!url) {
        return url;
    }

    if (url.charAt(0) === '/') {
        return url;
    }

    if (url.includes('http') || url.includes('https')) {
        return url;
    }

    return '/' + url;
}
