import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import EmailValidator from 'email-validator';
import fetch from 'react-storefront/fetch';

import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Image from 'next/image';
import RightArrow from 'public/images/icons/arrow-right.svg';

import { useTranslation } from 'tools/i18n';
import { SessionContext } from 'components/providers/session';
import theme from 'components/theme';
import { TrackingContext } from 'components/providers/tracking';
import { useRouter } from "next/router";

const useStyles = makeStyles(
    {
        '@keyframes couponCopied': {
            '0%': {
                transform: 'scale(1,1)'
            },
            '50%': {
                transform: 'scale(1.1, 1.1)'
            },
            '100%': {
                transform: 'scale(1,1)'
            }
        },
        wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 400,
            width: '100%',
            padding: 0,
            borderRadius: 0,
            outline: 'none',
            backgroundColor: '#f3f3f3',
            [theme.breakpoints.down('sm')]: {
                left: 20,
                transform: 'translateY(-50%)',
                width: 'calc(100% - 40px)'
            }
        },
        framer: {
            margin: 10,
            padding: 10,
            border: 'thin solid #b9b9b9'
        },
        image: {
            display: 'block',
            [theme.breakpoints.down('md')]: {
                '& img': {
                    objectFit: 'cover',
                    objectPosition: 'bottom center',
                    height: 'auto',
                    width: '100%'
                }
            }
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '15px 0 0',
            textAlign: 'center',
            margin: '0 auto',
            maxWidth: 300,
            '&.comfy': {
                maxWidth: 'none'
            }
        },
        close: {
            position: 'absolute',
            top: -13,
            right: -13,
            minWidth: 0,
            padding: 4,
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            '&:hover': {
                backgroundColor: '#01011d'
            }
        },
        title: {
            margin: '0 0 10px',
            fontFamily: 'Chronicle',
            fontSize: 42,
            fontStyle: 'italic',
            fontWeight: 100,
            lineHeight: 1
        },
        text: {
            margin: '0',
            maxWidth: 400,
            fontSize: 12,
            lineHeight: '18px',
            color: '#000'
        },
        list: {
            display: 'none',
            color: '#666'
        },
        listItem: {
            position: 'relative',
            padding: '6px 0 6px 20px',
            fontSize: 14,
            '&:before': {
                display: 'block',
                position: 'absolute',
                left: 0,
                top: '50%',
                content: '""',
                width: 10,
                height: 10,
                borderRadius: '50%',
                border: '1px solid #c0c0c0',
                transform: 'translateY(-50%)'
            }
        },
        actions: {
            display: 'flex',
            marginTop: 15,
            width: '100%',
            maxWidth: 350,
            '&.countrySelector': {
                // flexWrap: 'wrap'
            }
        },
        countrySelectWrapper: {
            display: 'none',
            textAlign: 'left',
            borderRadius: 0
        },
        countrySelect: {
            padding: '15.5px 0 15.5px 15.5px',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Chronicle',
        },
        inputWrapper: {
            flexGrow: 1
        },
        input: {
            border: '1px solid #cacaca',
            borderRadius: 0,
            backgroundColor: 'white',
            '.countrySelector &': {
                borderRadius: 0,
                //borderLeftWidth: 0
            }
        },
        inputLabel: {
            fontSize: 14,
            fontStyle: 'italic',
            fontFamily: 'Chronicle'
        },
        button: {
            minWidth: 0,
            display: 'block',
            padding: '12px 16px',
            fontSize: 12,
            fontWeight: 600,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 0,
            lineHeight: 0,
            '&:hover': {
                backgroundColor: '#01011d'
            },
            '.countrySelector &': {
                // marginTop: 10,
                // width: '100%',
                borderRadius: 0
            }
        },
        buttonFramer: {
            position: 'absolute',
            // width: '100%',
            // height: '100%',
            top: 3,
            bottom: 3,
            left: 3,
            right: 3,
            border: 'thin solid rgba(255,255,255,.7)'
        },
        buttonArrow: {
            width: 10,
            height: 10,
            fill: 'white',
            lineHeight: 0
        },
        integrity: {
            fontFamily: 'Chronicle',
            textTransform: 'uppercase',
            marginBottom: 5,
            marginTop: 10,
            alignSelf: 'center',
            fontSize: 10,
            lineHeight: '14px',
            letterSpacing: 1,
            '& a': {
                color: 'inherit'
            }
        },
        success: {
            alignItems: 'center'
        }
    },
    { name: 'IntroPopup' }
);

export default function IntroPopup() {
    const classes = useStyles();
    const router = useRouter();
    const { t } = useTranslation('popup');
    const {
        token,
        salesChannel,
        config,
        countries,
        geoIpCountry,
        actions: sessionActions
    } = useContext(SessionContext);
    const { actions: trackingActions } = useContext(TrackingContext);
    const [popupConfig, setPopupConfig] = useState({popups_intro_active: true});
    const [open, setOpen] = useState(false);
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showCountrySelector, setShowCountrySelector] = useState(false);
    const [country, setCountry] = useState(false);
    const customFields = salesChannel?.translated?.customFields;
    const storeId = salesChannel?.id;

    useEffect(() => {
        if (popupConfig && popupConfig?.popups_intro_active) {
            if (!Cookies.get('NPIntroPopup') && !hasEclubData() && !isSocialTraffic() && !isInStoreDisplay()) {
                setTimeout(() => {
                    if(!Cookies.get('NPIntroPopup') && !hasEclubData() && window.location.href.indexOf('checkout') < 0) {
                        setOpen(true);
                    }
                }, 60000);
            }
        }
    }, [popupConfig, router.asPath]);

    useEffect(() => {
        if (!token) {
            return;
        }

        setPopupConfig({
            popups_intro_active: customFields?.popups_intro_active
        });

        if (config.language.locale === 'en') {
            getCountries().then();
        }
    }, [token]);

    useEffect(() => {
        if (countries && countries.length > 1) {
            setShowCountrySelector(true);

            getInitialSalesChannelCountry();
        }
    }, [countries]);

    function isSocialTraffic() {
        let path = router.asPath;

        if(path.indexOf('utm_') > 0) {
            return true
        }

        return false;
    }

    function hasEclubData() {
        let eclubData = sessionActions.getEclubData();

        return eclubData;
    }

    function isInStoreDisplay() {
        return window?.navigator?.userAgent?.indexOf('NEWPORT_INSTOREBROWSER') >= 0 ? true : false;
    }

    async function getCountries() {
        await sessionActions.getCountries();
    }

    function handleClose() {
        const reason = success ? 'submitted' : 'closed';
        setOpen(false);
        Cookies.set('NPIntroPopup', true, { expires: success ? 90 : 30 });
    }

    function getInitialSalesChannelCountry() {
        if(geoIpCountry) {
            let correctGeoIPCountry = countries.filter((country) => {
                return country.iso === geoIpCountry;
            });

            if(correctGeoIPCountry && correctGeoIPCountry.length > 0) {
                setCountry(correctGeoIPCountry[0].iso);
            }
        }
    }

    function validateEmail(email) {
        return EmailValidator.validate(email);
    }

    async function newsletterSubmit() {
        if (!validateEmail(newsletterEmail)) {
            return;
        }

        let subscriberCountry = countries.length > 1 ? country : config?.language?.languageCode.split('-')[1];

        try {
            setLoading(true);

            let response = await fetch('/api/voyado/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: newsletterEmail,
                    country: subscriberCountry || null,
                    store: storeId,
                    source: 'popup',
                })
            });

            let registrationData = await response.json();

            if (registrationData.status === 'error') {
                if (registrationData.message.indexOf('Member exists') >= 0) {
                    setError(t('errors:signup-already-exists'));
                } else if (registrationData.message.indexOf('Request is invalid') >= 0) {
                    setError(t('errors:signup-request-invalid'));
                } else {
                    setError(registrationData.message);
                }
            } else {
                setError(false);
                setNewsletterEmail('');
                setSuccess(true);

                trackingActions.click({
                    type: 'footer_newsletter',
                    text: t('popup:intro-subscribe'),
                    identifier: 'signup_success'
                });
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(t('errors:signup-general-error'));

            setLoading(false);
        }
    }

    return popupConfig?.popups_intro_active ? (
        <Modal
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            disableScrollLock={true}
        >
            <div className={classes.wrapper}>
                <div className={classes.framer}>
                    <div className={classes.image}>
                        <Image
                            src={'/images/popup/intro_popup-2023_11_09.jpg'}
                            alt={t('intro-title')}
                            width={800}
                            height={620}
                            className={classes.image}
                        />
                    </div>
                    <div className={[classes.info, showCountrySelector ? 'comfy' : ''].join(' ')}>
                        <Button className={classes.close}>
                            <Close style={{ fontSize: 18 }} onClick={handleClose} />
                        </Button>
                        <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: t('intro-title') }}></h1>
                        <p className={classes.text}>{t('intro-text')}</p>
                        {error ? (
                            <Grid item>
                                <Alert severity="error" style={{ marginBottom: 10 }}>
                                    <span dangerouslySetInnerHTML={{ __html: error }} />
                                </Alert>
                            </Grid>
                        ) : (
                            ''
                        )}
                        {success ? (
                            <Alert severity={'success'} className={classes.success} style={{ marginTop: 10 }}>
                                {t('popup:newsletter-signup-success')}
                            </Alert>
                        ) : (
                            <div className={[classes.actions, showCountrySelector ? 'countrySelector' : ''].join(' ')}>
                                {showCountrySelector ? (
                                    <Select
                                        value={country}
                                        onChange={event => setCountry(event.target.value)}
                                        classes={{
                                            select: classes.countrySelect
                                        }}
                                        className={classes.countrySelectWrapper}
                                        variant={'outlined'}
                                        size={'small'}
                                        autoWidth={true}
                                    >
                                        {countries
                                            .sort((a, b) => {
                                                if (a.iso < b.iso) {
                                                    return -1;
                                                }

                                                if (a.iso > b.iso) {
                                                    return 1;
                                                }
                                            })
                                            .map(country => (
                                                <MenuItem value={country.iso}>{country.translated?.name}</MenuItem>
                                            ))}
                                    </Select>
                                ) : (
                                    ''
                                )}

                                <TextField
                                    type="email"
                                    variant={'filled'}
                                    size={'small'}
                                    label={t('popup:intro-email-placeholder')}
                                    className={classes.inputWrapper}
                                    error={!!newsletterEmail && !validateEmail(newsletterEmail)}
                                    value={newsletterEmail}
                                    onChange={event => setNewsletterEmail(event.target.value)}
                                    inputProps={{ className: classes.input }}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ className: classes.inputLabel }}
                                />

                                <Button className={classes.button} onClick={() => newsletterSubmit()}>
                                    <>
                                        {/*{t('intro-subscribe')}*/}
                                        <div className={classes.buttonFramer} />
                                        {loading ? (
                                            <CircularProgress size={10} color={'inherit'} />
                                        ) : (
                                            <RightArrow className={classes.buttonArrow} />
                                        )}
                                    </>
                                </Button>
                            </div>
                        )}
                        <p className={classes.integrity} dangerouslySetInnerHTML={{ __html: t('intro-integrity') }}></p>
                    </div>
                </div>
            </div>
        </Modal>
    ) : (
        ''
    );
}
